import Strings from "src/shared/Strings";
export class BuiltCampaignFlowStep {
    constructor(response, context) {
        Object.assign(this, response);
        this.context = context;
    }
    getIconType() {
        return (this.icon_type || "").replace(/^fa-/, "").replace(/^fas-/, "");
    }
    getDisplayName() {
        return this.name || Strings.capitalize(this.step_name);
    }
    getIconClasses() {
        const iconTypeClass = `far fa-${this.getIconType()}`;
        const iconColorClass = `flow-item__icon--${this.icon_color}`;
        return `${iconTypeClass} ${iconColorClass}`;
    }
    isPromoteStep() {
        return ["promotion_viewed"].includes(this.step_name);
    }
    getJourneyRole() {
        if (this.icon_color === "primary") {
            return "ADVOCATE";
        }
        else {
            return "FRIEND";
        }
    }
    isBusinessEvent() {
        return this.flow_path.startsWith("/business-events");
    }
}
