import _ from "underscore";
import BuiltCampaignIncentiveTransitionRule from "./BuiltCampaignIncentiveTransitionRule";
import BuiltCampaignIncentiveQualityRule from "./BuiltCampaignIncentiveQualityRule";
class BuiltCampaignIncentive {
    constructor(campaignIncentiveResponse) {
        Object.assign(this, campaignIncentiveResponse);
        this.transition_rules = _.map(campaignIncentiveResponse.transition_rules, (response) => {
            return new BuiltCampaignIncentiveTransitionRule(response);
        });
        this.quality_rules = _.map(campaignIncentiveResponse.quality_rules, (response) => {
            return new BuiltCampaignIncentiveQualityRule(response);
        });
    }
}
export default BuiltCampaignIncentive;
