import Session from "Session";
import { BuiltCampaignControllerAction } from "./BuiltCampaignControllerAction";
export const isBuiltCampaignControllerActionCreative = (action) => {
    return action.action_type === "CREATIVE";
};
export class BuiltCampaignControllerActionCreative extends BuiltCampaignControllerAction {
    constructor(response, context) {
        super(response, context);
        this.action_id = response.action_id;
        this.action_type = response.action_type;
        this.quality = response.quality;
        this.enabled = response.enabled;
        this.component_ids = response.component_ids;
        this.build_version = response.build_version;
        this.classification = response.classification;
        this.api_version = response.api_version;
        this.theme_version = response.theme_version;
    }
    getDownloadUrl() {
        const downloadUrl = `api:///v2/campaigns/${this.context.campaignId}/controllers/${this.context.stepId}/actions/creatives/${this.action_id}.zip`;
        return String(Session.getInstance().ajaxPrefilter.processUrl(downloadUrl));
    }
}
