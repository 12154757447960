export class BuiltCampaignControllerAction {
    constructor(response, context) {
        this.action_id = response.action_id;
        this.action_type = response.action_type;
        this.quality = response.quality;
        this.enabled = response.enabled;
        this.component_ids = response.component_ids;
        this.context = context;
    }
}
