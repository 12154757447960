import faro from "src/shared/third-party-integrations/faro";
import { BuiltCampaignControllerTriggerShare } from "../BuiltCampaignControllerTriggerShare";
import { BuiltCampaignControllerTriggerGeneric } from "../BuiltCampaignControllerTriggerGeneric";
import { BuiltCampaignControllerTriggerEvent } from "../BuiltCampaignControllerTriggerEvent";
import { BuiltCampaignControllerTriggerHasPriorReward } from "../BuiltCampaignControllerTriggerHasPriorReward";
import { BuiltCampaignControllerTriggerLegacyQuality } from "../BuiltCampaignControllerTriggerLegacyQuality";
import { BuiltCampaignControllerTriggerScore } from "../BuiltCampaignControllerTriggerScore";
import { BuiltCampaignControllerTriggerZoneState } from "../BuiltCampaignControllerTriggerZoneState";
const isBuiltCampaignControllerTriggerShareResponse = (response) => response.trigger_type === "SHARE";
const subclasses = {
    SHARE: BuiltCampaignControllerTriggerShare,
    EVENT: BuiltCampaignControllerTriggerEvent,
    SCORE: BuiltCampaignControllerTriggerScore,
    ZONE_STATE: BuiltCampaignControllerTriggerZoneState,
    REFERRED_BY_EVENT: BuiltCampaignControllerTriggerGeneric,
    LEGACY_QUALITY: BuiltCampaignControllerTriggerLegacyQuality,
    EXPRESSION: BuiltCampaignControllerTriggerGeneric,
    ACCESS: BuiltCampaignControllerTriggerGeneric,
    DATA_INTELLIGENCE_EVENT: BuiltCampaignControllerTriggerGeneric,
    HAS_PRIOR_STEP: BuiltCampaignControllerTriggerGeneric,
    MAXMIND: BuiltCampaignControllerTriggerGeneric,
    REWARD_EVENT: BuiltCampaignControllerTriggerGeneric,
    SEND_REWARD_EVENT: BuiltCampaignControllerTriggerGeneric,
    AUDIENCE_MEMBERSHIP: BuiltCampaignControllerTriggerGeneric,
    AUDIENCE_MEMBERSHIP_EVENT: BuiltCampaignControllerTriggerGeneric,
    HAS_PRIOR_REWARD: BuiltCampaignControllerTriggerHasPriorReward,
    HAS_IDENTITY: BuiltCampaignControllerTriggerGeneric,
    CLIENT_DOMAIN: BuiltCampaignControllerTriggerGeneric,
    LEGACY_LABEL_TARGETING: BuiltCampaignControllerTriggerGeneric,
};
function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
const createInstance = (type, response, context) => {
    const SubClass = subclasses[type];
    return new SubClass(response, context);
};
export const createBuiltCampaignControllerTriggers = (responses, context) => {
    const steps = [];
    responses.forEach((response) => {
        switch (response.trigger_type) {
            case "SHARE":
                if (isBuiltCampaignControllerTriggerShareResponse(response)) {
                    steps.push(createInstance("SHARE", response, context));
                }
                break;
            case "EVENT":
            case "SCORE":
            case "ZONE_STATE":
            case "REFERRED_BY_EVENT":
            case "LEGACY_QUALITY":
            case "EXPRESSION":
            case "ACCESS":
            case "DATA_INTELLIGENCE_EVENT":
            case "HAS_PRIOR_STEP":
            case "MAXMIND":
            case "REWARD_EVENT":
            case "SEND_REWARD_EVENT":
            case "AUDIENCE_MEMBERSHIP":
            case "AUDIENCE_MEMBERSHIP_EVENT":
            case "HAS_PRIOR_REWARD":
            case "HAS_IDENTITY":
            case "CLIENT_DOMAIN":
            case "LEGACY_LABEL_TARGETING":
                steps.push(createInstance(response.trigger_type, response, context));
                break;
            default:
                assertNever(response.trigger_type);
                faro.pushError(new Error(`Unknown type, ${response.trigger_type}`));
        }
    });
    return steps;
};
