import _ from "underscore";
import dateTimeService from "src/shared/date-time-service";
export const HIDDEN_TAG_NAME = "hidden";
const OPEN_DATE_NAME = "Forever";
export const HIDDEN_TAGS = [
    "marketing",
    "influencer-v2",
    "international",
    HIDDEN_TAG_NAME,
    "internal:.*",
];
class CampaignSummary {
    constructor(campaignSummaryResponse) {
        this.campaign_id = campaignSummaryResponse.campaign_id;
        this.campaign_locks = campaignSummaryResponse.campaign_locks;
        this.campaign_type = campaignSummaryResponse.campaign_type;
        this.description = campaignSummaryResponse.description;
        this.is_published = campaignSummaryResponse.is_published;
        this.labels = campaignSummaryResponse.labels;
        this.last_published_date = campaignSummaryResponse.last_published_date;
        this.start_date = campaignSummaryResponse.start_date;
        this.pause_date = campaignSummaryResponse.pause_date;
        this.stop_date = campaignSummaryResponse.stop_date;
        this.name = campaignSummaryResponse.name;
        this.program_label = campaignSummaryResponse.program_label;
        this.program_type = campaignSummaryResponse.program_type;
        this.state = campaignSummaryResponse.state;
        this.tags = campaignSummaryResponse.tags;
        this.theme_name = campaignSummaryResponse.theme_name;
        this.updated_date = campaignSummaryResponse.updated_date;
    }
    clone() {
        return new CampaignSummary(JSON.parse(JSON.stringify(this)));
    }
    hasDraft() {
        if (!this.last_published_date) {
            return true;
        }
        const publishedDate = new Date(this.last_published_date);
        const updatedDate = new Date(this.updated_date);
        return updatedDate > publishedDate;
    }
    isLive() {
        return this.state === "LIVE";
    }
    isPaused() {
        return this.state === "PAUSED";
    }
    isArchived() {
        return this.state === "ARCHIVED";
    }
    isEnded() {
        return this.state === "ENDED";
    }
    isHidden() {
        var _a;
        return (_a = this.tags) === null || _a === void 0 ? void 0 : _a.includes(HIDDEN_TAG_NAME);
    }
    isLocked() {
        return !_.isEmpty(this.campaign_locks);
    }
    getLastUpdatedPretty() {
        return dateTimeService.formatToDateTime(this.updated_date);
    }
    getLastPublishedPretty() {
        return this.last_published_date
            ? dateTimeService.formatToDateTime(this.last_published_date)
            : null;
    }
    isScheduled() {
        return (dateTimeService.isDateInFuture(this.start_date) ||
            (this.start_date !== null &&
                dateTimeService.isDateInFuture(this.stop_date)) ||
            dateTimeService.isDateInFuture(this.pause_date) ||
            (this.pause_date !== null &&
                dateTimeService.isDateInFuture(this.stop_date)));
    }
    isOpenEndDated() {
        return this.isScheduled() && this.stop_date === null;
    }
    getStartDatePretty() {
        return this.start_date
            ? dateTimeService.formatToFullDateTime(this.start_date)
            : null;
    }
    getEndDatePretty() {
        return this.stop_date
            ? dateTimeService.formatToFullDateTime(this.stop_date)
            : OPEN_DATE_NAME;
    }
    getLastUpdatedDescription() {
        if (this.is_published) {
            return `Published: ${this.getLastPublishedPretty()}`;
        }
        return `Draft Saved: ${this.getLastUpdatedPretty()}`;
    }
    isConfigurationCampaing() {
        var _a;
        return (_a = this.tags) === null || _a === void 0 ? void 0 : _a.includes("internal:configuration");
    }
    isMarketingCampaign() {
        return this.campaign_type === "MARKETING";
    }
    isGlobal() {
        return (this.program_type === "global-campaign" && this.name === "global");
    }
}
export default CampaignSummary;
