import { BuiltCampaignControllerTrigger } from "./BuiltCampaignControllerTrigger";
export const isBuiltCampaignControllerTriggerEvent = (trigger) => {
    return trigger.trigger_type === "EVENT";
};
export class BuiltCampaignControllerTriggerEvent extends BuiltCampaignControllerTrigger {
    constructor(response, context) {
        super(response, context);
        this.event_names = response.event_names;
        this.step_names = response.step_names;
        this.event_type = response.event_type;
        this.event_name = response.event_name;
        this.step_name = response.step_name;
    }
    getInputEventNames() {
        return this.event_names;
    }
    getInputEventName() {
        return this.getInputEventNames()[0];
    }
}
