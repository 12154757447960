import { ComponentCommonImpl } from "../../component/model/ComponentCommonImpl";
export class BuiltCampaignComponent {
    constructor(response) {
        this.componentCommonImpl = new ComponentCommonImpl(response);
        this.id = response.id;
        this.component_version = response.component_version;
        this.name = response.name;
        this.description = response.description;
        this.tags = response.tags;
        this.component_ids = response.component_ids;
        this.created_date = response.created_date;
        this.updated_date = response.updated_date;
        this.types = this.componentCommonImpl.types;
    }
    getDisplayName() {
        return this.componentCommonImpl.getDisplayName();
    }
    isRoot() {
        return this.componentCommonImpl.isRoot();
    }
    hasCreative() {
        return this.componentCommonImpl.hasCreative();
    }
}
export default BuiltCampaignComponent;
