import _ from "underscore";
import CampaignSummary from "../../campaign-summary/model/CampaignSummary";
import { isBuiltCampaignJourneyEntry, } from "./BuiltCampaignJourneyEntry";
import { BuiltCampaignFlowStep } from "./BuiltCampaignFlowStep";
import BuiltCampaignIncentive from "./BuiltCampaignIncentive";
import BuiltCampaignComponent from "./BuiltCampaignComponent";
import { isTestEmailCompatible } from "src/shared/tags/campaigns";
import { isBuiltCampaignFrontendController, } from "./BuiltCampaignFrontendController";
import { isBuiltCampaignController, } from "./BuiltCampaignController";
import { createBuiltCampaignSteps } from "./factory/builtCampaignStepFactory";
import { isBuiltCampaignControllerActionCreative, } from "./BuiltCampaignControllerActionCreative";
import { forEach } from "lodash";
import { isBuiltCampaignControllerActionDisplay, } from "./BuiltCampaignControllerActionDisplay";
const doLegacyCreativesExist = (apiVersionsWithinCampaign) => {
    const hasOnlyV8Creatives = apiVersionsWithinCampaign.size === 1 &&
        apiVersionsWithinCampaign.has(8);
    return !hasOnlyV8Creatives;
};
const getCreativeApiVersions = (actions) => {
    const apiVersions = new Set();
    actions.forEach((action) => {
        apiVersions.add(action.api_version);
    });
    return apiVersions;
};
export class BuiltCampaign extends CampaignSummary {
    constructor(builtCampaignResponse) {
        super(builtCampaignResponse);
        this.version = builtCampaignResponse.version;
        this.variants = builtCampaignResponse.variants;
        this.steps = createBuiltCampaignSteps(builtCampaignResponse.steps, this.campaign_id, this.version);
        this.flow_steps = _.map(builtCampaignResponse.flow_steps, (response) => new BuiltCampaignFlowStep(response, {
            campaign_id: this.campaign_id,
        }));
        this.incentive = new BuiltCampaignIncentive(builtCampaignResponse.incentive);
        this.components = _.map(builtCampaignResponse.components, (response) => new BuiltCampaignComponent(response));
        this.creativeApiVersions = getCreativeApiVersions(this.getCreativeActions());
        this.hasLegacyCreatives = doLegacyCreativesExist(this.creativeApiVersions);
        this.isTestEmailCompatible = isTestEmailCompatible(builtCampaignResponse.tags);
    }
    getRootComponentId() {
        var _a;
        return (_a = this.components.find((component) => component.isRoot())) === null || _a === void 0 ? void 0 : _a.id;
    }
    getController(controllerId) {
        const controllers = this.getControllers();
        return _.find(controllers, (controller) => controller.id === controllerId);
    }
    getControllers() {
        const output = [];
        _.each(this.steps, (step) => {
            if (isBuiltCampaignController(step)) {
                output.push(step);
            }
        });
        return output;
    }
    getFrontendControllers() {
        const output = [];
        _.each(this.steps, (step) => {
            if (isBuiltCampaignFrontendController(step)) {
                output.push(step);
            }
        });
        return output;
    }
    getJourneyEntries() {
        const output = [];
        _.each(this.steps, (step) => {
            if (isBuiltCampaignJourneyEntry(step)) {
                output.push(step);
            }
        });
        return output;
    }
    getJourneyEntry(journeyEntryId) {
        const journeyEntries = this.getJourneyEntries();
        return _.find(journeyEntries, (journeyEntry) => journeyEntry.id === journeyEntryId);
    }
    getFlowStep(flowStepId) {
        return _.find(this.flow_steps, (flowStep) => flowStep.flow_step_id === flowStepId);
    }
    isV8() {
        const campaign = this;
        const stepWithV8Creative = campaign.steps.find((step) => {
            if (isBuiltCampaignFrontendController(step)) {
                const creativeAction = step.actions.find((action) => {
                    if (isBuiltCampaignControllerActionCreative(action)) {
                        return action.api_version >= 8;
                    }
                });
                return Boolean(creativeAction);
            }
            return false;
        });
        return Boolean(stepWithV8Creative);
    }
    isLocked() {
        return !_.isEmpty(this.campaign_locks);
    }
    getCreativeActions() {
        const output = [];
        forEach(this.steps, (step) => {
            if (isBuiltCampaignFrontendController(step)) {
                forEach(step.actions, (action) => {
                    if (isBuiltCampaignControllerActionCreative(action)) {
                        output.push(action);
                    }
                });
            }
        });
        return output;
    }
    getDisplayActions() {
        const output = [];
        forEach(this.steps, (step) => {
            if (isBuiltCampaignFrontendController(step)) {
                forEach(step.actions, (action) => {
                    if (isBuiltCampaignControllerActionDisplay(action)) {
                        output.push(action);
                    }
                });
            }
        });
        return output;
    }
    getCreativeAction(actionId) {
        return _.find(this.getCreativeActions(), (action) => action.action_id === actionId);
    }
    getTriggers() {
        const output = [];
        _.each(this.steps, (step) => {
            step.triggers.forEach((trigger) => {
                output.push(trigger);
            });
        });
        return output;
    }
    getStep(stepId) {
        return _.find(this.steps, (step) => step.id === stepId);
    }
    isLegacyCampaign() {
        return this.hasLegacyCreatives;
    }
}
