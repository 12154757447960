var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElMessageBox } from "element-plus";
export const confirm = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const showCancelButton = options.showCancelButton !== undefined
        ? options.showCancelButton
        : true;
    const showClose = options.showClose !== undefined ? options.showClose : true;
    return yield ElMessageBox.confirm(options.message, {
        title: options.title,
        confirmButtonText: options.confirmButtonText || "Confirm",
        customClass: "el-message-box--confirm",
        confirmButtonClass: "button button--primary",
        cancelButtonClass: "button button--secondary",
        beforeClose: (action, instance, done) => {
            if (!options.onConfirm) {
                done();
                return;
            }
            if (action === "confirm") {
                instance.confirmButtonLoading = true;
                options.onConfirm(() => {
                    done();
                }, () => {
                    instance.confirmButtonLoading = false;
                });
            }
            else {
                done();
            }
        },
        showCancelButton,
        showClose,
    });
});
