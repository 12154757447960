import { BuiltCampaignStep } from "./BuiltCampaignStep";
export const isBuiltCampaignJourneyEntry = (step) => {
    return step.type === "JOURNEY_ENTRY";
};
export class BuiltCampaignJourneyEntry extends BuiltCampaignStep {
    constructor(response, context) {
        super(response, context);
        this.journey_name = response.journey_name;
        this.priority = response.priority;
    }
    getDisplayName() {
        return this.journey_name;
    }
}
