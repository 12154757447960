import { createBuiltCampaignControllerTriggers } from "./factory/builtCampaignControllerTriggerFactory";
import dateTimeService from "src/shared/date-time-service";
export class BuiltCampaignStep {
    constructor(response, context) {
        this.type = response.type;
        this.id = response.id;
        this.enabled = response.enabled;
        this.triggers = createBuiltCampaignControllerTriggers(response.triggers, {
            campaignId: context.campaignId,
            stepId: this.id,
        });
        this.component_ids = response.component_ids;
        this.created_date = response.created_date;
        this.updated_date = response.updated_date;
        this.context = context;
    }
    getDisplayName() {
        return this.id;
    }
    getUpdatedDatePretty() {
        return dateTimeService.formatToDateTime(this.updated_date);
    }
    getCreatedDatePretty() {
        return dateTimeService.formatToDateTime(this.created_date);
    }
}
