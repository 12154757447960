import { BuiltCampaignStep } from "./BuiltCampaignStep";
import { createBuiltCampaignControllerActions } from "./factory/builtCampaignControllerActionFactory";
import Strings from "src/shared/Strings";
import { isBuiltCampaignControllerTriggerEvent } from "./BuiltCampaignControllerTriggerEvent";
export const isBuiltCampaignController = (step) => {
    return step.type === "CONTROLLER";
};
export class BuiltCampaignController extends BuiltCampaignStep {
    constructor(response, context) {
        super(response, context);
        this.id = response.id;
        this.name = response.name;
        this.scope = response.scope;
        this.enabled_on_states = response.enabled_on_states;
        this.selectors = response.selectors;
        this.actions = createBuiltCampaignControllerActions(response.actions, {
            campaignId: context.campaignId,
            stepId: response.id,
            campaignVersion: context.campaignVersion,
        });
        this.aliases = response.aliases;
        this.data = response.data;
        this.journey_names = response.journey_names;
    }
    getDisplayName() {
        return Strings.capitalize(this.name);
    }
    getUniquePartnerEventKeyNames() {
        return this.data
            .filter((data) => data.key_type === "UNIQUE_PARTNER_EVENT_KEY")
            .map((data) => data.name);
    }
    getDataNames() {
        return this.data.map((data) => data.name);
    }
    getInputEventNames() {
        const inputEventNames = new Set();
        this.triggers.forEach((trigger) => {
            if (isBuiltCampaignControllerTriggerEvent(trigger)) {
                trigger.getInputEventNames().forEach((inputEventName) => {
                    inputEventNames.add(inputEventName);
                });
            }
        });
        return Array.from(inputEventNames);
    }
}
