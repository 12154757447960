import $ from "jquery";
import _ from "underscore";
import Uri from "jsuri";
const baseUrl = "api:///v2/campaigns";
export var CampaignControllerTriggerPhase;
(function (CampaignControllerTriggerPhase) {
    CampaignControllerTriggerPhase["MATCHING"] = "MATCHING";
    CampaignControllerTriggerPhase["QUALIFYING"] = "QUALIFYING";
    CampaignControllerTriggerPhase["QUALITY"] = "QUALITY";
})(CampaignControllerTriggerPhase || (CampaignControllerTriggerPhase = {}));
export var CampaignControllerTriggerType;
(function (CampaignControllerTriggerType) {
    CampaignControllerTriggerType["SHARE"] = "SHARE";
    CampaignControllerTriggerType["EVENT"] = "EVENT";
    CampaignControllerTriggerType["SCORE"] = "SCORE";
    CampaignControllerTriggerType["ZONE_STATE"] = "ZONE_STATE";
    CampaignControllerTriggerType["REFERRED_BY_EVENT"] = "REFERRED_BY_EVENT";
    CampaignControllerTriggerType["LEGACY_QUALITY"] = "LEGACY_QUALITY";
    CampaignControllerTriggerType["EXPRESSION"] = "EXPRESSION";
    CampaignControllerTriggerType["ACCESS"] = "ACCESS";
    CampaignControllerTriggerType["DATA_INTELLIGENCE_EVENT"] = "DATA_INTELLIGENCE_EVENT";
    CampaignControllerTriggerType["HAS_PRIOR_STEP"] = "HAS_PRIOR_STEP";
    CampaignControllerTriggerType["MAXMIND"] = "MAXMIND";
    CampaignControllerTriggerType["REWARD_EVENT"] = "REWARD_EVENT";
    CampaignControllerTriggerType["HAS_PRIOR_REWARD"] = "HAS_PRIOR_REWARD";
    CampaignControllerTriggerType["HAS_IDENTITY"] = "HAS_IDENTITY";
})(CampaignControllerTriggerType || (CampaignControllerTriggerType = {}));
const getCampaigns = (queryParams) => {
    const uri = new Uri(baseUrl);
    if (queryParams) {
        _.each(queryParams, (value, key) => {
            uri.addQueryParam(key, value);
        });
    }
    return $.ajax({
        method: "GET",
        url: uri.toString(),
    });
};
const getCampaign = (campaignId, version) => {
    return $.ajax({
        method: "GET",
        url: `${baseUrl}/${campaignId}${version ? "/version/" + version : ""}`,
    });
};
const editCampaign = (campaignId, data) => $.ajax({
    method: "PUT",
    url: `${baseUrl}/${campaignId}`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const publish = (campaignId, data) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/publish`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const discard = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/discard`,
});
const archive = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/archive`,
});
const unarchive = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/unarchive`,
});
const lock = (campaignId, data) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/lock`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const unlock = (campaignId, data) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/unlock`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const duplicate = (campaignId, data) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/duplicate`,
    contentType: "application/json",
    data: data ? JSON.stringify(data) : {},
});
const pause = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/pause`,
});
const end = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/end`,
});
const stop = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/stop`,
});
const launchTest = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/launch-test`,
});
const launchBurst = (campaignId) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/launch-burst`,
});
const schedule = (campaignId, data) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/schedule`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const scheduledPause = (campaignId, version, data) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/pause`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const makeLatestDraft = (campaignId, version) => $.ajax({
    method: "POST",
    url: `${baseUrl}/${campaignId}/version/${version}/make-latest-draft`,
    contentType: "application/json",
});
const getBuiltCampaigns = () => $.ajax({
    method: "GET",
    url: "api:///v2/campaigns/built",
});
const getBuiltCampaign = (campaignId, data = {}) => $.ajax({
    method: "GET",
    url: `${baseUrl}/${campaignId}/built`,
    data,
});
const getVersions = (campaignId, data = {}) => $.ajax({
    method: "GET",
    url: `${baseUrl}/${campaignId}/versions`,
    data,
});
const getVersion = (campaignId, version) => $.ajax({
    method: "GET",
    url: `${baseUrl}/${campaignId}/versions/${version}`,
});
const getTemplateCampaigns = () => $.ajax({
    method: "GET",
    url: `${baseUrl}/template`,
});
export default {
    getCampaigns,
    getTemplateCampaigns,
    getCampaign,
    editCampaign,
    getBuiltCampaign,
    getBuiltCampaigns,
    publish,
    discard,
    archive,
    unarchive,
    lock,
    unlock,
    duplicate,
    pause,
    end,
    stop,
    launchTest,
    launchBurst,
    schedule,
    scheduledPause,
    getVersions,
    getVersion,
    makeLatestDraft,
};
